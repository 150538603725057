import "reflect-metadata";
import "@/styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { SessionProvider, useSession } from "next-auth/react";
import Navigation from "@/components/Navigation/Navigation";
import { DM_Sans } from "next/font/google";
import { trpc } from "../utils/trpc";
import clsx from "clsx";
import { useState } from "react";
import {
  UserOrganizationContext,
  UserOrganizationDispatchContext,
  UserOrganizations,
} from "@/contexts/global/OrganizationContext";
import {
  OrganizationBrands,
  OrganizationBrandsContext,
  OrganizationBrandsDispatchContext,
} from "@/contexts/global/BrandContext";
import Footer from "@/components/Navigation/Footer";
import { Toaster } from "@/components/ui/sonner";

const TITLE = "Treater";

const nunito = DM_Sans({
  subsets: ["latin", "latin-ext"],
  weight: ["400", "500", "700"],
  variable: "--font-main",
});

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [userOrganizations, setUserOrganizations] = useState<UserOrganizations | null>(null);
  const [organizationBrands, setOrganizationBrands] = useState<OrganizationBrands | null>(null);
  const isIndex = router.pathname === "/";
  const title = isIndex ? TITLE : `${(Component as any).title} - ${TITLE}`;
  const noNav = (Component as any).noNav ?? false;

  return (
    <>
      <TreaterHead title={title} pageProps={pageProps} />
      <SessionProvider session={pageProps.session}>
        <UserOrganizationContext.Provider value={userOrganizations}>
          <UserOrganizationDispatchContext.Provider value={setUserOrganizations}>
            <OrganizationBrandsContext.Provider value={organizationBrands}>
              <OrganizationBrandsDispatchContext.Provider value={setOrganizationBrands}>
                {/* */}
                <EnsureAuthSettled>
                  <TreaterBody noNav={noNav}>
                    <Component {...pageProps}></Component>
                  </TreaterBody>
                </EnsureAuthSettled>
                {/* */}
              </OrganizationBrandsDispatchContext.Provider>
            </OrganizationBrandsContext.Provider>
          </UserOrganizationDispatchContext.Provider>
        </UserOrganizationContext.Provider>
      </SessionProvider>
    </>
  );
}

function EnsureAuthSettled({ children }: React.PropsWithChildren): JSX.Element {
  const session = useSession();
  if (session.status === "loading") return <>Loading...</>;
  return <>{children}</>;
}

function TreaterHead({ title, pageProps }: { title: string; pageProps: AppProps["pageProps"] }) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={pageProps.description}></meta>
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={title}></meta>
      <meta property="og:description" content={pageProps.description}></meta>
      <meta property="og:image" content="https://trytreater.com/treater.png"></meta>
      <meta property="og:url" content="https://trytreater.com"></meta>
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:site" content="@trytreater"></meta>
      <meta name="twitter:creator" content="@trytreater"></meta>
      <meta name="twitter:title" content={title}></meta>
      <meta
        name="twitter:description"
        content={pageProps.description || "Treater drives retail velocity for CPG brands."}
      ></meta>
      <meta name="twitter:image" content="https://trytreater.com/treater.png"></meta>
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href="https://trytreater.com"></link>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
  );
}

function TreaterBody({ children, noNav }: { children: React.ReactNode; noNav?: boolean }) {
  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        html {
          font-family: ${nunito.style.fontFamily};
        }
      `}</style>
      <div className={clsx(`flex flex-col min-h-screen antialiased ${nunito.variable} font-sans`)}>
        <Toaster />
        {!noNav ? <Navigation /> : <></>}
        <div className="flex grow">{children}</div>
        <Footer />
      </div>
    </>
  );
}

export default trpc.withTRPC(App);
