import { JOrganization } from "@/server/routers/user/organizations";
import { OrganizationUserRole } from "@/types/OrganizationUserRole";
import { useContext } from "react";
import { createContext } from "react";

export interface UserOrganizations {
  active: { organization: JOrganization; role: OrganizationUserRole };
  available: JOrganization[];
}

export const UserOrganizationContext = createContext<UserOrganizations | null>(null);
export const UserOrganizationDispatchContext = createContext<
  (org: UserOrganizations | null) => void
>(() => {});

export function useUserOrganizations() {
  return useContext(UserOrganizationContext);
}

export function useUserOrganizationsDispatch() {
  return useContext(UserOrganizationDispatchContext);
}
